@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
html {
  scroll-behavior: smooth;
}
* {
  user-select: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  /* transition: all 0.3s ease-in-out; */
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) inset;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    180deg,
    #169b8a 0%,
    rgba(19, 163, 16, 0.6) 99%
  );
  border-radius: 10px;
  height: 10px !important;
  margin: 5px;
  border: 2px solid #fff;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.mainContainer {
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainContainer .backGround {
  background-color: #169b8a;
  width: 100vw;
  height: 100vh;
  clip-path: polygon(0 0, 78% 0, 34% 100%, 0 100%);
}
.mainContainer .innerContainer {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.logo {
  letter-spacing: 2px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 3em;
  color: #169b8a;
  opacity: 0.8;
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-text-stroke-width: 2px;
}

.formContainer {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  /* animation: slideFromTop 1s ease-in-out forwards; */
}

.bbb:hover{
  background-color: #169b8a !important;
}
/* @keyframes slideFromTop {
  from{
    transform: translateY(-500px);
  }
  to {
    transform: translateY(0px);
  }
} */
.grandContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.01);
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05) inset;
}
.grandContainer .mainBtn {
  letter-spacing: 5px;
  margin-top: 20px;
}
.FormContainer {
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  background-color: rgba(22, 155, 138, 0.1);
  margin-bottom: 20px;
}
.FormContainer .innerContainer {
  width: 100%;
}
.FormContainer .innerContainer .formTitle {
  width: 100%;
  font-weight: bold;
  text-align: start;
  font-size: 1.8em;
  text-transform: uppercase;
  color: #169b8986;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  letter-spacing: 5px;
}
.FormContainer .innerContainer form {
  margin: 20px;
}
.FormContainer .innerContainer form .innerGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 10px;
}
.FormContainer .innerContainer form .innerGroup .input {
  width: 100%;
}
.Textinput{
  width: 100%;
}
.FormContainer .innerContainer form .innerGroup p {
  width: 30%;
  text-align: start;
  text-transform: capitalize;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.FormContainer .innerContainer p.subTitle {
  width: 100%;
  text-align: start;
  text-transform: capitalize;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  margin: 10px 0;
  margin-top: 50px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.FormContainer .innerContainer p.errorText {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: rgba(251, 0, 0, 0.6);
  letter-spacing: 1px;
  margin: 10px 0;
  font-size: 0.7em;
  text-transform: uppercase;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.FormContainer .innerContainer p.successText {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: rgba(37, 170, 50, 0.6);
  letter-spacing: 1px;
  margin: 10px 0;
  font-size: 0.7em;
  text-transform: uppercase;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.FormContainer .innerContainer .imageIcon {
  color: rgba(0, 0, 0, 0.1);
  font-size: 3em;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.6);
}
.FormContainer .innerContainer .imageIcon.done {
  color: rgba(19, 163, 16, 0.6);
}
.FormContainer .innerContainer .imageContainer {
  overflow: hidden;
  margin-top: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
  border-radius: 15px;
}

.imagemain {
  display: flex;
  justify-content: center;
}
.FormContainer .innerContainer .imageContainer img {
  margin: 5px;
  border: 2px solid rgba(255, 255, 255, 0.7);
}
.FormContainer .innerContainer .logoImage {
  /* margin: 15px auto; */
  border-radius: 20px;
  object-fit: cover;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  border-radius: "10px";
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 5px;
  display: block;
}
/* .FormContainer .innerContainer form .innerGroup p:hover,
.FormContainer .innerContainer p.subTitle:hover,
.FormContainer .innerContainer p.errorText:hover,
.FormContainer .innerContainer p.successText:hover{
  letter-spacing: 2px;
} */
.FormContainer .innerContainer form .innerGroup p span {
  color: rgb(251, 0, 0);
}

/* Student Card */
.cardContainer {
  height: 100%;
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cardContainer::-webkit-scrollbar {
  width: 0px;
}
.cardContainer .cardList {
  border-radius: 8px;
  width: 900px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1) inset;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(35, 172, 92, 0.1);
  margin: 5px 0;
}
.cardContainer .cardList .splitLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardContainer .cardList .cardimg {
  width: 60px !important;
  height: 60px;
  border-radius: 100%;
  object-fit: cover;
  border: 3px solid rgba(19, 163, 16, 0.6);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}
.cardContainer .cardList .sno {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background-color: rgba(255, 255, 255, 0.6);
  width: 40px;
  height: 40px;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 3px 20px 20px 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.4);
}
.cardContainer .cardList .cardInfo {
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.cardContainer .cardList .cardInfo .cardId {
  font-size: 1.3em;
  text-transform: capitalize;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}
.cardContainer .cardList .cardInfo .cardId span {
  font-size: 1.4em;
  color: rgba(35, 172, 92, 0.5);
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
.cardContainer .cardList .cardInfo .cardName {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: -5px;
  color: rgba(0, 0, 0, 0.4);
}
.cardContainer .cardList .cardInfo .cardName span {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.5);
}

.profileimagediv {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.loader {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
}
.loader div {
  background: #169b8986;
  width: 6px;
  height: 20px;
  border-radius: 5px;
  transform-origin: 10px 35px;
  transform: rotate(0deg);
  position: absolute;
  left: 45px;
  top: 15px;
  animation: loader 0.8s infinite;
}
.loader div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: 0.1s;
}
.loader div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: 0.2s;
}
.loader div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: 0.3s;
}
.loader div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: 0.4s;
}
.loader div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: 0.5s;
}
.loader div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: 0.6s;
}
.loader div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: 0.7s;
}
@keyframes loader {
  0% {
    background: transparent;
    transform-origin: 10px 35px;
    left: 45px;
  }
  30% {
    background: #169b8986;
  }
  100% {
    background: transparent;
    transform-origin: -10px 35px;
    left: 55px;
  }
}
